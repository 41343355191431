import { motion, MotionValue, useScroll, useTransform } from 'motion/react'

import { useBreakpoint } from '../hooks/useBreakpoint'
import { Button } from '../components/Button'
// import phones from '../assets/hero/hero-phones-desktop.webp'

import proParents from '../assets/hero/hero-phone-pro-parents.webp'
import goDutch from '../assets/hero/hero-phone-go-dutch.webp'

import illustrationPeakfijn from '../assets/hero/illustration-peakfijn.webp'
import illustrationComputer from '../assets/hero/illustration-computer.webp'
import illustrationRocket from '../assets/hero/illustration-rocket.webp'
import illustrationMoney from '../assets/hero/illustration-money.webp'
import { useTranslation } from 'react-i18next'
import { useRef } from 'react'

function useParallax(value: MotionValue<number>, distance: number) {
  return useTransform(value, [0, 1], [-distance, distance])
}

export function Hero() {
  const isMediumDevice = useBreakpoint('sm')
  const isExtraLargeDevice = useBreakpoint('lg')
  const { t } = useTranslation('hero')

  const ref = useRef(null)
  const { scrollYProgress } = useScroll({ target: ref })
  const yPosImage1 = useParallax(scrollYProgress, 50)
  const yPosImage2 = useParallax(scrollYProgress, 75)

  const stats = [
    {
      image: illustrationPeakfijn,
      key: t('howWeWork.stats.0.key'),
      value: t('howWeWork.stats.0.value'),
    },
    {
      image: illustrationComputer,
      key: t('howWeWork.stats.1.key'),
      value: t('howWeWork.stats.1.value'),
    },
    {
      image: illustrationRocket,
      key: t('howWeWork.stats.2.key'),
      value: t('howWeWork.stats.2.value'),
    },
    {
      image: illustrationMoney,
      key: t('howWeWork.stats.3.key'),
      value: t('howWeWork.stats.3.value'),
    },
  ]

  const steps = [
    t('howWeWork.steps.0'),
    t('howWeWork.steps.1'),
    t('howWeWork.steps.2'),
    [t('howWeWork.steps.3.0'), t('howWeWork.steps.3.1')],
  ]

  return (
    <section className="mt-[4rem] lg:mt-[11.5rem]">
      <div className="inner-container relative" ref={ref}>
        <h1 className="font-display font-bold text-2xl md:text-3xl leading-none">
          {t('title1')}
          {isMediumDevice ? <br /> : ' '}
          <em className="text-orange-600 not-italic">{t('title2')}</em>
        </h1>
        <p className="mt-4 md:mt-6 body">{t('subtitle')}</p>
        <Button
          as="a"
          className="mt-5 md:mt-8 inline-flex"
          size={isMediumDevice ? 'large' : 'medium'}
          href={t('url') as string}
        >
          {t('cta')}
        </Button>

        <motion.img
          src={goDutch}
          alt={t('goDutchAlt')}
          className="w-[220px] absolute -right-[20px] top-[-11rem] hidden lg:block rounded-lg shadow-xl"
          initial={{ opacity: 0, translateY: 40 }}
          animate={{ opacity: 1, translateY: 0 }}
          transition={{ ease: 'easeOut', delay: 0.05 }}
          style={{ translateY: yPosImage1 }}
        />
        <motion.img
          src={proParents}
          alt={t('proParentsAlt')}
          className="w-[220px] absolute right-[120px] top-[-8rem] hidden lg:block rounded-lg shadow-xl"
          initial={{ opacity: 0, translateY: 40 }}
          animate={{ opacity: 1, translateY: 0 }}
          transition={{ ease: 'easeOut' }}
          style={{ translateY: yPosImage2 }}
        />
      </div>

      <div className="outer-container mt-16 md:mt-[8.5rem] ">
        <div className="border border-neutral-600/10 rounded-xl lg:rounded-2xl shadow-md px-4 py-5 md:p-12 lg:p-20 bg-white">
          <h2 className="font-display text-orange-600 text-sm md:text-base mb-4 md:mb-12">
            {t('howWeWork.title')}
          </h2>
          <div className="flex justify-between">
            <div className="space-y-4 md:space-y-8">
              {steps.map((step, index) => (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: index * 0.1 }}
                  className="flex items-start"
                  key={index}
                >
                  <div className="text-neutral-600 text-xs md:text-base w-6 mr-2 md:mr-8 mt-px leading-relaxed">
                    0{index + 1}.
                  </div>
                  {typeof step === 'string' ? (
                    <p className="text-neutral-800 flex-1 text-sm md:text-lg leading-snug">
                      {step}
                    </p>
                  ) : (
                    <p className="text-orange-600 flex-1 text-sm md:text-lg leading-snug">
                      {step[0]}
                      {isMediumDevice ? <br /> : ' '}
                      {step[1]}
                    </p>
                  )}
                </motion.div>
              ))}
            </div>

            {isExtraLargeDevice && (
              <div className="space-y-12 px-[6rem] whitespace-nowrap">
                {stats.map((stat, index) => (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: steps.length * 0.1 + index * 0.1 }}
                    className="flex items-center"
                    key={index}
                  >
                    <img
                      src={stat.image}
                      alt={stat.key}
                      className="mr-6 w-10"
                    />
                    <div>
                      <p className="text-neutral-600">{stat.key}</p>
                      <p className="font-display font-bold text-xl leading-snug text-neutral-800">
                        {stat.value}
                      </p>
                    </div>
                  </motion.div>
                ))}
              </div>
            )}
          </div>
        </div>

        {!isExtraLargeDevice && (
          <div className="mt-8 md:mt-12 mx-auto max-w-[20rem] md:max-w-[34rem] grid grid-cols-2 gap-y-6 md:gap-y-8">
            {stats.map((stat) => (
              <div className="flex items-center" key={stat.key}>
                <img
                  src={stat.image}
                  alt={stat.key}
                  className="mr-2 md:mr-4 w-5 sm:w-6 md:w-10"
                />
                <div>
                  <p className="text-xs md:text-base text-neutral-600">
                    {stat.key}
                  </p>
                  <p className="font-display font-bold text-sm md:text-xl text-neutral-800 leading-snug">
                    {stat.value}
                  </p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  )
}
