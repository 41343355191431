import {
  ComponentPropsWithoutRef,
  ElementType,
  PropsWithChildren,
  ReactNode,
  useMemo,
} from 'react'

export type IconComponent = React.ComponentType<{
  color?: string
  size?: number
}>

const defaultElement = 'button'

type ButtonProps<Element extends ElementType = typeof defaultElement> =
  PropsWithChildren<ComponentPropsWithoutRef<Element>> & {
    IconLeftComponent?: ReactNode
    IconRightComponent?: ReactNode
    children?: React.ReactNode
    variant?: 'solid' | 'outline' | 'ghost'
    size?: 'small' | 'medium' | 'large'
    as?: Element
  }

export function Button<Element extends ElementType = typeof defaultElement>({
  children,
  IconLeftComponent,
  IconRightComponent,
  variant = 'solid',
  size = 'medium',
  className = '',
  as,
  ...props
}: ButtonProps<Element>) {
  const Component = as ?? defaultElement

  const finalClassName = useMemo(() => {
    let classes =
      'group flex justify-center items-center font-display font-bold rounded-md focus:outline-none focus:ring-0 select-none transition-colors duration-150'

    if (variant === 'solid') {
      classes += ` text-white bg-orange-600 hover:bg-orange-700 active:bg-orange-800 disabled:bg-neutral-500`
    } else if (variant === 'outline') {
      classes += ` bg-neutral-0 border border-orange-300 text-orange-600 hover:text-orange-700 hover:border-orange-400 active:border-orange-500 active:text-orange-800 disabled:text-neutral-500 disabled:border-neutral-200`
    } else if (variant === 'ghost') {
      classes += ` bg-neutral-0 text-neutral-700 hover:text-neutral-800 hover:bg-neutral-100 active:text-neutral-900 active:bg-neutral-200 disabled:text-neutral-500 disabled:bg-transparent`
    }

    if (size === 'small') {
      classes += ` h-8 px-2.5 text-xs`
    } else if (size === 'medium') {
      classes += ` h-8 px-2.5 text-sm`
    } else if (size === 'large') {
      classes += ` h-10 px-6 text-base`
    }

    return (classes += ` ${className}`)
  }, [className, size, variant])

  return (
    <Component className={finalClassName} {...props}>
      {IconLeftComponent && <div className="mr-2">{IconLeftComponent}</div>}
      <div className="whitespace-nowrap">{children}</div>
      {IconRightComponent && <div className="ml-2">{IconRightComponent}</div>}
    </Component>
  )
}
